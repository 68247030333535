import React, {useState, FC, MouseEvent, BaseSyntheticEvent} from 'react';

import axios from 'axios';
import styled from 'styled-components';

import { BlobCollection, FormDataType } from '../redventures-service-form';
import {nullValidation, Input } from '../red-ventures-service-form/ServiceForm';
import { conformation } from '../../assets/data/red-ventures/conformation';
import NewLogo from '../../assets/images/logo.png';

const FormPanel = styled.div`
    border: 2px solid black;
    margin: 10px 10px 20px 10px;
    font-family: Arial, Helvetica, sans-serif;
`;

const PanelHead = styled.div`
    display: flex;
    flex-direction: row;
`;

const ClientInfo = styled.div`
    padding: 10px;
    font-size:16px;    
    width: 85%;
`;

const ClientInfoItem = styled.div`
   padding-top: 5px;
`;

const ClientInfoTitle = styled.span`
   font-weight: 600; 
   padding-right: 15px;
`;

const Logo = styled.img`
    width: 10%;
    padding: 10px;
`

const PanelMain = styled.div`
   display: grid;
   grid-template-columns: 8% 8% auto 16% 12% 10% 7% 5% 12% 5%;
   @media screen and (max-width: 1024px){
        font-size: 12px;
   }
   @media screen and (max-width: 900px){
        font-size: 10px;
   }
   @media screen and (max-width: 767px){
        font-size: 9px;
   }
   @media screen and (max-width: 480px){
        font-size: 8px;
   }
`;

const PanelMainSection = styled.div`
    text-align: center;
`;

const PanelMainThread = styled.div`
    background-color: #1b1b1b;
    color: #fcfafa;
    @media screen and (max-width: 767px){
        padding: 2px;
   }
`;

const PanelMainEmpty = styled.div`
    background-color: #373434;
    height: 15px;
`;

const PanelMainContent = styled.div`
    color: black;
    padding: 5px;
    height: 20px;
    border-right: 1px solid black;
    @media screen and (max-width: 767px){
        padding: 2px;
        height: 15px;
    }
`;

const PanelBottom = styled.div`
    padding: 60px 10px 10px 10px;
    height: 460px;
    font-size: 14px;
`;

const BindingText = styled.div`
    font-weight: 600;
    margin: 10px 10px 10px 0px;
`;

const Signature = styled.div`
    margin: 30px 30% 30px 0px;
    line-height: 20px;   
`;

const SignatureItem = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: right;
    line-height: 30px;
    font-size: 14px;
    text-align: right; 
    margin-bottom: 10px;
    labeL {
        padding-right: 15px;
    }
    input{
        text-align: left;       
        width: 50%;
        background-color: #eee; 
        border: hidden;
        border-bottom: 2px solid black;
    }
`;

const Terms = styled.div`
    input{
        margin: 10px 5px 15px 5px;
    }
`;

const TermsContent = styled.div`
    margin-left: 10px;
`;

const Error = styled.div`
    color: red;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 50px;
    padding: 5px;
`;
const SubmitSucceed = styled.div`
    padding-top: 100px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
`;

const ButtonGroup = styled.div`
    padding: 10px 10px 20px 10px;
    margin-left: 60%;
    button {
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 2px;
        border-radius: 7px;
        color: #e6e0db;
        width: 32%;        
    }
    .back {
        background-color: ${(props : ButtonType)=> props.children[0].props.disabled ? '#9a958a' : '#161615'}; 
    }
    .submit {
        background-color: ${(props : ButtonType)=> props.children[1].props.disabled ? '#9a958a' : '#161615'}; 
    }
`;

interface FormConformationProps {
    overviewInput: Input;
    campaignInfoInput: Input;
    imageFile: BlobCollection;
    updateDisplay: (arg: boolean) => void;
};

interface ButtonType {
    disabled?: boolean;
    children: any[];
}

const ServiceFormConformation : FC<FormConformationProps> = ({ overviewInput, campaignInfoInput, imageFile, updateDisplay }) => {
    const servicesData : FormDataType[] = [
        {
            name: 'strategy',
            title: 'STRATEGY',
            content: 'Display'
        }, {
            name: 'goal',
            title:  'GOAL',
            content: campaignInfoInput?.campaignKPI
        }, {
            name: 'targetAudience',
            title: 'TARGET AUDIENCE',
            content: campaignInfoInput?.targetAudience
        }, {
            name: 'impressions',
            title: 'EST. IMPRESSIONS',
            content: campaignInfoInput?.impressions
        }, {
            name: 'startDate',
            title: 'START DATE',
            content: campaignInfoInput?.startDate
        }, {
            name: 'endDate',
            title: 'END DATE',
            content: campaignInfoInput?.endDate
        }, {
            name:'pricing',
            title: 'PRICING',
            content: 'CPM' 
        }, {
            name: 'rate',
            title: 'RATE',
            content: '$12.00' 
        }, {
            name:'totalCost',
            title: 'TOTAL COST',
            content: overviewInput?.budget
        }, {
            name:'geo',
            title: 'Geo',
            content: campaignInfoInput?.geoTargeting //geoTargeting
        }
    ];

    const initialSignature = {
        "Authorized Name": '',
        "Date": ''
    };

    const [formData, setFormData]= useState<FormDataType[]>(servicesData);
    const [signatureInput, setSignatureInput] = useState<Input>(initialSignature);
    const [termsBoxChecked, setTermsBoxChecked] = useState<boolean>(false);

    const [requiredError, setRequiredError] = useState<string>('');
    const [termsBoxCheckedError, setTermBoxCheckedError] = useState<string>('');

    const handleSignatureInput = (e : BaseSyntheticEvent) => {
        setSignatureInput ({
            ...signatureInput,
            [e.target.name]: e.target.value
        });
    }

    const handleSignatureInputLeave = (e :  BaseSyntheticEvent) => {
        const newSignatureInput = Object.values(signatureInput);
        if(requiredError) nullValidation(newSignatureInput, 'Signature Fields', setRequiredError);
    }

    const handleTermsBox = (e : BaseSyntheticEvent ) => {
        setTermsBoxChecked(e.target.checked);
    }

    const handleTermsBoxLeave = (e : BaseSyntheticEvent) => {
        if(e.target.checked) setTermBoxCheckedError('');
    }

    const onBack = () => {
        updateDisplay(true); 
    }

    const onSubmit = async (e : MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const failNullValidation = nullValidation(Object.values(signatureInput),'Signature Fields', setRequiredError);
 
        if(!termsBoxChecked) setTermBoxCheckedError('You Must Check the terms and Conditions Box')
 
        if(failNullValidation || !termsBoxChecked) return;
        const imageNames = campaignInfoInput['creativesSpecs'];

        imageNames?.map ((d: string, i: number) => {
            let formDataTemp = new FormData();
            formDataTemp.append('file', imageFile[d]);
            formDataTemp.append("mimeType", "image/png");
            formDataTemp.append("name", "Creative Specs");
            fetch('https://audigent.com/image', {
                 method: 'POST',
                 body: formDataTemp
            }).then((res) => {
                console.log("success", res);
            })
            .catch(e => {
                console.log(e)
            });
        })
   
       try{
            await axios.post('https://audigent.com/operation/red-ventures-service', { 
                 overviewInput, 
                 campaignInfoInput,
                 imageNames
            })
      
            setFormData([]);
            localStorage.setItem('formData',''); 
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <FormPanel>
        <PanelHead >
            <ClientInfo>
                <ClientInfoItem>
                    <ClientInfoTitle>Advertiser/Client Name:</ClientInfoTitle>{overviewInput !== {} && overviewInput?.advertiserName ? overviewInput?.advertiserNaame : ''}                      
                </ClientInfoItem>                            
                <ClientInfoItem>
                <ClientInfoTitle>Campaign Name:</ClientInfoTitle>
                    {overviewInput !== {} && overviewInput?.campaignName ? overviewInput.campaignName : ''}
                </ClientInfoItem>                  
            </ClientInfo>
            <Logo src={NewLogo} alt ='logo'/>
        </PanelHead>
        <PanelMain>
            {
                servicesData.map(d => 
                    <PanelMainSection key = {d.title}>
                        <PanelMainThread>{d.title}</PanelMainThread>
                        <PanelMainEmpty />
                        <PanelMainContent>{d.content}</PanelMainContent>
                        <PanelMainEmpty />
                    </PanelMainSection>
            )}                                 
        </PanelMain>
        <PanelBottom>
            {
                formData.length !== 0 ? 
                <>
                <div>{conformation.note}</div>
                <BindingText>{conformation.bindingText}</BindingText>
                <Signature>
                    {
                        ["Authorized Name", "Date"].map((d,i) => 
                        <SignatureItem key={d}>
                            <label htmlFor={d}>{`${d}: `}</label> 
                            <input 
                                value={signatureInput[d as keyof Input]||''} 
                                name={d}
                                id={i.toString()}
                                onChange={e=> handleSignatureInput(e)}
                                onMouseLeave={e=>handleSignatureInputLeave(e)}
                            />
                        </SignatureItem>)
                    }
                    <Error key='error-required'>{requiredError}</Error>
                </Signature>
                 
                <Terms>                  
                    <h3>TERMS AND CONDITIONS</h3>
                    <TermsContent>
                        {
                            conformation.terms.map((d, j) =>
                            <div key={`term${j}`}
                                id={`term${j}`}
                                dangerouslySetInnerHTML={{ __html: d.term}}
                            />)                                   
                        } 
                    </TermsContent>
                    
                    <input 
                        type="checkbox" 
                        onChange={e =>handleTermsBox(e)}
                        onMouseLeave={e=>handleTermsBoxLeave(e)}
                    />
                    <label>By selecting that box, you conform that you agree to the above terms and conditions</label>  
                    <Error key='terms-box-checked-error'>{termsBoxCheckedError}</Error>   
                </Terms>                    
                </>
                : 
                <SubmitSucceed>Great! You have successfully submitted your service form. </SubmitSucceed>
            }
        </PanelBottom>
            <ButtonGroup>
                <button className='back' onClick={onBack} disabled={false}>Back</button>
                <button className='submit' onClick={onSubmit} disabled={formData.length===0}>Submit</button>
           </ButtonGroup>
        </FormPanel>
    );   
}

export default ServiceFormConformation;